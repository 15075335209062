import axios from 'axios'
import configs from './config.js';
import { Message } from 'element-ui'
import { getToken } from './common.js';


const md5 = require('js-md5');
const service = axios.create({
    baseURL: configs.api.baseUrl,
    timeout:30000,//请求超时
    method:'post',
});


service.interceptors.request.use(
    (config)=> {
		let sign_time 	= (new Date()).valueOf();
		let sign 		= md5(sign_time + configs.api.appKey + "version=" + configs.api.version + configs.api.appSecret);
		let user_id		= getToken();
		let old 		= config.data;
		
		try {
		  old.time2  	= sign_time;
		  old.sign  	= sign;
		  old.user_id   = user_id;
		}catch(e) {
		  console.log(e)
		}
		config.data     = old;
		return config;
    },
    (error)=> {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data

        // if the custom code is not 1, it is judged as an error.
        if (res.code !== 1) {
            Message({
                message: res.msg || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
	
)

export default service
