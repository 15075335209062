import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './mock'
import dataV from '@jiaminghi/data-view'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts'


//引入config配置文件
import config from './static/js/config.js';
Vue.prototype.config = config;


Vue.prototype.$echarts = echarts
Vue.use(ElementUI);
Vue.use(dataV)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
document.title = config.site.name;
