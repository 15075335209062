import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from "js-cookie";
import {
	getToken
} from "../static/js/common";
import store from '../store'
Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'index',
		component: () => import('../views/index')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login')
	},
	{
		path: '/tickets',
		name: 'Tickets',
		component: () => import('../views/Tickets'),
	},
	{
		path: '/nianka',
		name: 'Nianka',
		component: () => import('../views/Nianka'),
	},
	{
		path: '/weather',
		name: 'Weather',
		component: () => import('../views/Weather')
	},
	{
		path: '/user',
		name: 'user',
		component: () => import('../views/user')
	},
	{
		path: '/wechat',
		name: 'wechat',
		component: () => import('../views/wechat')
	},
	{
		path: '/clinic',
		name: 'clinic',
		component: () => import('../views/clinic')
	},
	{
		path: '/make',
		name: 'make',
		component: () => import('../views/make')
	},
]

const router = new VueRouter({
	mode: 'history',
	routes
})
router.beforeEach((to, from, next) => {	
	const hasToken = getToken()
	if (hasToken) {
		store.dispatch('getInfo', hasToken).then(() => {
			if (to.path !== '/login') { //如果是登录页面路径，就直接next()
				//检查权限
				var auth = Cookies.get('auth');
				if(!auth && to.path!=='/login'){
					next('/login')
				}
				var menu = JSON.parse(auth);
				var state = false;
				for (var item of menu) {
					if (to.path == item.auth_url) {
						state = true;
					}
				}
				if (!state) {
					next(menu[0].auth_url);
				}			
				next();
			} else {
				next('/')
			}
		}).catch(() => {
			store.dispatch('resetToken').then(() => {
				next('/login')
			})
		})
	} else {
		if (to.path === '/login') { //如果是登录页面路径，就直接next()
			next();
		} else { //不然就跳转到登录；
			next('/login');
		}
	}
})
export default router
