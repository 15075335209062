module.exports = {
	site:{														//站点配置
		name: '哪儿疼平台大数据中心', 							//网站名称
		color: '#00AFFF', 										//程序主色
	},
	api:{														//接口配置
		baseUrl: 'https://api.naerteng.com/v3/', 				//接口根域名
		appKey: 'v3', 											//接口版本号
		version: 'v3', 											//接口key
		appSecret: 'qwertyuiopasdfghjklzxcvbnm123', 			//接口secret
	},
	
	
};
