<template>
  <div id="app">
    <dv-loading v-if="loading && $route.path!=='/login'" style="color: white">Loading...</dv-loading>
    <router-view v-if="!loading"/>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        loading:true
      }
    },
    mounted() {
      setTimeout(()=>{
        this.loading=false
      },100)
    }
  }
</script>
<style>
  .dv-loading {
    height: 100vh !important;
  }

</style>
