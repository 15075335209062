import request from '../static/js/request'

export function getWechatInfo() {
    return request({
        url: 'chart_wechat_data',
        method: 'post',
        data:{}
    })
}
export function getUserInfo() {
    return request({
        url: 'chart_user_data',
        method: 'post',
        data:{}
    })
}


export function login(data) {
    return request({
        url: 'user_login',
        method: 'post',
        data
    })
}

export function getInfo(token) {
    return request({
        url: 'user_info',
        method: 'post',
        data:{token:token}
    })
}

export function logout() {
    return request({
        url: 'admin/login/logout',
        method: 'post'
    })
}
