import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import {getInfo, login} from "../api/common";
import {removeToken, setToken,getToken} from "../static/js/common";
import Cookies from "js-cookie";
import * as echarts from 'echarts'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:getToken(),
    username:'admin',
    echarts_color1:'#389af4',
    echarts_color2:'#ff8c37',
    echarts_color3:'#ffc257',
    echarts_color4:'#fd6f97',
    echarts_color5:'#a181fc',
    linear_color:new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
      offset: 0,
      color: "#0B4EC3" // 0% 处的颜色
    }, {
      offset: 0.6,
      color: "#138CEB" // 60% 处的颜色
    }, {
      offset: 1,
      color: "#17AAFE" // 100% 处的颜色
    }], false)
  },
  mutations: {
    SET_TOKEN(state, token){
      state.token = token
    },
    SET_NAME(state, name){
      state.username = name
    },
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        login(data).then(res => {
          commit('SET_TOKEN', res.data.id)
		  Cookies.set('auth',res.data.big_auth);
		  Cookies.set('user',res.data);//储存用户信息
          setToken(res.data.id)
          router.push({
            path: '/'
          }).then(()=>{
            console.log('登陆成功')
          }).catch(() => {})
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(res => {
          commit('SET_NAME', res.data.user_username)//储存用户名
          Cookies.set('auth',res.data.big_auth);//储存权限
          Cookies.set('user',res.data);//储存用户信息
          resolve(res.data)
        }).catch(error => {
          commit('SET_TOKEN', '')
          reject(error)
        })
      })
    },
    // 退出登录
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        removeToken()
        router.push({
            path: '/login'
        }).then(()=>{
          console.log('注销成功')
        }).catch(() => {})
        resolve(true)
      })
    },
    // 清除token
    resetToken({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    },
  },
  modules: {
  }
})
