import Mock from "mockjs";
const baseURL= 'http://bigdataapi.zykongtong.com/v1'; // url = base url + request url
//拦截请求，返回假数据
Mock.mock(baseURL+'/admin/login/login', (option)=>{
    let rawData=JSON.parse(option.body)
    if(rawData.username==='admin' && rawData.password==='123456'){
        return{
            msg: "登陆成功!",
            data: {token:new Date().getTime()},
            code: 1
        }
    }
    else{
        return{
            msg: "密码错误!",
            data: {},
            code: 0
        }
    }
});
Mock.mock(baseURL+'/admin/login/getInfo', (option)=>{
    let rawData=JSON.parse(option.body)
    if(new Date().getTime()-rawData.token<5*60*1000){
        return{
            msg: "登陆成功!",
            data: {username:'admin'},
            code: 1
        }
    }
    else{
        return{
            msg: "登陆已过期!",
            data: {},
            code: 0
        }
    }
});
Mock.mock(baseURL+'/admin/login/logout', {
    msg: "注销成功",
    code: 1
});
